import React from 'react';
import { Link } from 'gatsby';

import { Wrapper, SEO } from '@components';

import * as s from './_styles';

const PrivacyPage = () => (
    <Wrapper>
        <SEO title="Return and Refund Policy" keywords={[`gatsby`, `application`, `react`]} />
        <h1 css={s.title}>Return and Refund Policy</h1>
        <div css={s.policy}>
            <div>
                <p>
                    All sales are final. &nbsp;We accept returns for refund or exchange, for items damaged in transit.
                </p>
                <h2>Refunds and Exchanges</h2>
                <p>
                    To be eligible for a refund or exchange, you must first <Link to="/contact">contact us</Link> within
                    24 hours of receiving your order to alert us of the damage, then return the damaged item to us. Upon
                    receipt of the damaged item we will ship out a replacement, if available. If a replacement is not
                    available we will refund the full purchase price of your item.
                </p>
                <p>To complete your return, we require a receipt or proof of purchase.</p>
                <p>
                    Any item not in its original condition, is damaged or missing parts for reasons not due to shipping
                    error will result in an ineligible return and/or refund.
                </p>
                <p>
                    Once your return is received and inspected, we will send you an email to notify you that we have
                    received your returned item. We will also notify you of the approval or rejection of your refund. If
                    you are approved, then your replacement or refund will be processed, and a credit will automatically
                    be applied to your credit card or original method of payment, within a certain amount of 7 days.
                </p>
                <h2>Late or missing refunds (if applicable)</h2>
                <p>If you haven’t received a refund yet, first check your bank account again.</p>
                <p>
                    Then contact your credit card company, it may take some time before your refund is officially
                    posted. Next contact your bank. There is often some processing time before a refund is posted.&nbsp;
                    If you’ve done all of this and you still have not received your refund yet, please
                    <Link to="/contact">contact us</Link>.
                </p>
                <h2>Shipping</h2>
                <p>
                    To return your product, you should <Link to="/contact">contact us</Link> prior to shipping the item.
                </p>
                <p>
                    You will be responsible for paying for your own shipping costs for returning your item. Shipping
                    costs are non-refundable. If you receive a refund, the cost of shipping will be deducted from your
                    refund.
                </p>
                <p>
                    Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
                    <br />
                </p>
                <p>
                    If you are shipping an item over $75, you should consider using a trackable shipping service or
                    purchasing shipping insurance. We don’t guarantee that we will receive your returned item.
                </p>
                <h2>Lost or Stolen Packages</h2>
                <p>
                    410 Smoke Shop is not responsible for lost or stolen packages confirmed to be delivered to the
                    address entered for an order. &nbsp;Upon inquiry, 410 Smoke shop will confirm delivery to the
                    address provided, date of delivery, tracking information and shipping carrier information for the
                    customer to investigate.
                </p>
            </div>
        </div>
    </Wrapper>
);

export default PrivacyPage;
